.product-item {
    background: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    width: 100%;
    display: inline-block;
    column-break-inside: avoid;
    margin-bottom: $grid-gutter-width;
    font-weight: 600;
    font-size: rem-calc(14);
    $elm: &;

    &__image-wrapper {
        position: relative;

        &:hover {
            #{$elm}__enlarge svg {
                transform: scale(1.1);
            }
        }
    }

    &__image {
        width: 100%;
        cursor: pointer;
    }

    &__enlarge {
        position: absolute;
        bottom: 10px;
        right: 10px;
        pointer-events: none;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "brand", "primary");
            height: 32px;
            width: 32px;
        }
    }

    &__content {
        padding: 20px;
    }

    &__list {
        padding-left: 1em;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1em;
        position: relative;

        &::before {
            content: "";
            width: 0.25em;
            height: 0.25em;
            background: map-deep-get($colors, "brand", "primary");
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 0.65em;
            left: 0;
        }
    }

    &__label {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "gray", "default");
        margin-bottom: 0.25em;
        margin-top: 2em;
        text-transform: uppercase;
    }

    &__link {
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        margin-top: 3em;
        font-weight: 600;
        text-decoration: underline;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }

        &:hover {
            color: map-deep-get($colors, "black", "default");
            background: map-deep-get($colors, "brand", "primary");
            padding-right: 30px;
        }
    }
}