.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "black", "default");
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    overflow: hidden;
    $header: &;

    @include media-breakpoint-up(md) {
        background-color: transparent;
        margin: 20px 0 0 auto;
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 120%;
        background: linear-gradient(180deg, map-deep-get($colors, "black", "default") 0%, rgba(map-deep-get($colors, "black", "default"), 0) 100%);

        @include media-breakpoint-up(md) {
            top: -20px;
        }
    }

    &--scrolled {
        background: map-deep-get($colors, "black", "default");
        margin-top: 0;

        @include media-breakpoint-up(md) {
            #{$header}__logo {
                height: #{map_get($header-height, "medium--scrolled") - 16px};
                width: 100px;
            }
        }

        @include media-breakpoint-up(xl) {
            #{$header}__logo {
                height: map_get($header-height, "medium--scrolled");
            }
        }
    }

    &--expanded {
        background: map-deep-get($colors, "black", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        transition: $transition-default;
        height: map_get($header-height, "small");
        padding-top: 8px;
        padding-bottom: 8px;
        display: block;

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
            height: #{map_get($header-height, "medium") - 16px};
            width: 123px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 8px;
            padding-bottom: 8px;
            height: map_get($header-height, "large");
            width: 145px;
        }

        svg {
            height: 100%;
            width: auto;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }

    &__contact-button {
        width: 100%;
        margin-bottom: 10px;
    }
}