.back-to-overview {
    vertical-align: middle;
    display: inline-flex;
    $elm: &;

    &__icon {
        display: inline-block;
        fill: map-deep-get($colors, "brand", "primary");
        height: 1em;
        margin-right: 0.25em;
        transition: $transition-default;
        vertical-align: middle;
        width: 2em;
    }

    &:hover {
        #{$elm}__icon {
            margin-right: 0.5em;
        }
    }
}