.main {
    display: block;
    max-width: 100%;
    padding-top: map_get($header-height, "small");
    transition: $transition-default;
    height: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
        padding-top: #{40px + map_get($header-height, "medium")};
    }

    @include media-breakpoint-up(lg) {
        padding-top: #{40px + map_get($header-height, "large")};
    }

    &--home {
        @include media-breakpoint-up(md) {
            margin-bottom: -100px;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: -155px;
        }
    }
}