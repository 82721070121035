.products-overview {
    &__show-more {
        margin-top: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    &--loading {
        opacity: 0.5;
    }
}