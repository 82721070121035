.languages {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }

    $elm: &;

    &__list {
        display: inline-block;

        /*
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: 0;
        }
        */
    }

    &__item {
        display: inline-block;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        text-transform: uppercase;
        color: map-deep-get($colors, "brand", "secondary");
        font-weight: 900;
        background: map-deep-get($colors, "white", "default");

        /*
        @include media-breakpoint-up(md) {
            display: none;

            &--active {
                display: block;
            }
        }
        */
    }

    &__link {
        color: inherit;
    }

    @include media-breakpoint-up(md) {
        &:hover {
            #{$elm}__item {
                display: block;
            }
        }
    }
}