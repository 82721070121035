.text-page {
    &__image {
        margin-top: 55px;
        margin-bottom: 55px;

        @include media-breakpoint-up(md) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }
}