.link {
    $elm: &;
    color: map-deep-get($colors, "black", "default");
    text-decoration: underline;

    &__icon {
        display: inline-block;
        height: 1.5em;
        margin-right: 0.5em;
        transition: $transition-default;
        vertical-align: middle;
        width: 2.5em;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &:hover {
        #{$elm}__icon {
            margin-right: 0.25em;
            margin-left: 0.25em;
        }
    }
}