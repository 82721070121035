.hero {
    margin-top: -#{map_get($header-height, "small")};
    background-position: center center;
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 50px;
    position: relative;

    /* stylelint-disable */
    height: 1px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    /* stylelint-enable */

    @include media-breakpoint-up(md) {
        margin-top: -#{40px + map_get($header-height, "medium")};
    }

    @include media-breakpoint-up(lg) {
        margin-top: -#{40px + map_get($header-height, "large")};

        /* stylelint-disable */
        min-height: 87.5vh;
        min-height: calc(var(--vh, 1vh) * 87.5);
        /* stylelint-enable */
    }

    $elm: &;

    &--homepage {
        background-position: center right;
        overflow: hidden;
        height: auto;

        @include media-breakpoint-up(md) {
            background-position: center center;

            /* stylelint-disable */
            min-height: 100vh;
            min-height: calc(100vh + #{40px + map_get($header-height, "medium")});
            min-height: calc(var(--vh, 1vh) * 100);
            /* stylelint-enable */
        }

        @include media-breakpoint-up(lg) {

            /* stylelint-disable */
            min-height: 100vh;
            min-height: calc(100vh + #{40px + map_get($header-height, "large")});
            min-height: calc(var(--vh, 1vh) * 100);
            /* stylelint-enable */
        }
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -1px;
    }

    &::before {
        background: linear-gradient(90deg, rgba(map-deep-get($colors, "black", "default"), 0.8) 0%, rgba(map-deep-get($colors, "black", "default"), 0) 70%);
        z-index: 5;
        right: 30%;
    }

    &::after {
        background: linear-gradient(0deg, map-deep-get($colors, "black", "default") 0%, rgba(map-deep-get($colors, "black", "default"), 0) 100%);
        z-index: 10;
        top: auto;
        height: 80%;
    }

    &__title {
        position: relative;
        z-index: 1;
    }

    &__inner {
        flex-flow: column nowrap;
        min-height: 100%;
        display: flex;
        padding-top: map-get($header-height, "small");
        position: relative;
        z-index: 20;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            padding-top: #{40px + map_get($header-height, "medium")};
        }

        @include media-breakpoint-up(xl) {
            padding-top: #{40px + map-get($header-height, "large")};
        }
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        min-height: 100%;
    }

    &__text {
        color: map-deep-get($colors, "gray", "default");
        -webkit-font-smoothing: antialiased;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -20px;
            right: -20px;
            bottom: -20px;
            left: -20px;
            background: rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 35px 20px rgba(0, 0, 0, 0.25);
            padding: 20px;
        }

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0;

            &::before {
                content: none;
            }
        }
    }

    &__rich-text {
        font-size: rem-calc(16);
        line-height: 1.64em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(25);
        }
    }

    &__projects {
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            margin-top: 75px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 100px;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 125px;
        }
    }

    &__link {
        color: map-deep-get($colors, "white", "default");
        display: block;
        margin-bottom: 0.5em;
        -webkit-font-smoothing: antialiased;
        text-decoration: underline;
        font-size: rem-calc(16);
        line-height: 1.375em;
    }

    &__button {
        margin-top: 20px;
    }

    &__controls {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
    }

    &__down-icon {
        display: inline-block;
        height: 2.2em;
        margin-right: 0.25em;
        transition: $transition-default;
        width: 1em;

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__down:hover {
        #{$elm}__down-icon {
            transform: translateY(50%);
        }
    }

    &__bg {
        background-position: center center;
        background-size: cover;
        height: 0;
        padding-top: 66.5%;
        width: 100%;
    }
}