.products {
    display: flex;
    flex-flow: row wrap;

    &__product {
        padding-left: #{$grid-gutter-width / 2};
        padding-right: #{$grid-gutter-width / 2};
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 33.33%;
        }
    }

    &__content {
        margin-top: 55px;
        margin-bottom: 55px;
        line-height: 1.9em;
        font-size: rem-calc(20);

        @include media-breakpoint-up(md) {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include media-breakpoint-up(md) {
            margin-top: 110px;
            margin-bottom: 110px;
        }
    }
}