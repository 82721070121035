.contact-form {
    margin-top: #{$grid-gutter-width * 2};

    @include media-breakpoint-up(md) {
        margin-top: -300px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: -400px;
    }
}