.hero-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__slides {
        height: 100%;
        width: 100%;
    }

    &__slide {
        height: 100%;
        width: 100%;
        transition: $transition-default;
        z-index: -1;
    }

    &__slide-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    /* stylelint-disable */
    .flickity-viewport {
        height: 100% !important;
    }

    .flickity-slider {
        transform: none !important;

        .hero-slider__slide {
            opacity: 0;
            left: 0 !important;

            &.is-selected {
                opacity: 1;
                z-index: 0
            }
        }
    }

    .flickity-page-dots {
        position: absolute;
        width: auto;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        z-index: 20;

        .dot {
            transition: $transition-default;
            display: block;
            background: map-deep-get($colors, "brand", "primary");
            position: relative;
            margin-bottom: 15px;
            opacity: 1;
            width: 8px;
            height: 8px;

            &::before {
                transition: $transition-default;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid transparent;
            }

            &.is-selected {
                &::before {
                    border-color: map-deep-get($colors, "brand", "primary");
                    top: -6px;
                    right: -6px;
                    bottom: -6px;
                    left: -6px;
                }
            }
        }
    }
    /* stylelint-enable */
}