.contact-block {
    background: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    padding: 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
        padding: 24px 25px 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 40px 40px 20px;
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        margin-bottom: 1em;
    }

    &__row {
        margin: 1em 0 1.5em;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__value {
        min-height: 2.25em;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
        }
    }

    &__icon {
        display: inline-block;
        height: 2em;
        margin-right: 1em;
        vertical-align: middle;
        width: 2em;

        @include media-breakpoint-up(lg) {
            height: 2.25em;
            width: 2.25em;
        }

        svg {
            height: 100%;
            width: 100%;
        }

        &--route {
            svg {
                height: 120%;
                width: 120%;
                margin: -10%;
            }
        }
    }

    &__link {
        text-decoration: underline;
    }

    &__kvk {
        font-weight: 600;
        margin-top: 0.8em;
        color: map-deep-get($colors, "gray", "default");
        -webkit-font-smoothing: antialiased;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
        }
    }
}