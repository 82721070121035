.products-filter {
    margin-top: 20px;
    margin-bottom: 20px;
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    &__label {
        font-weight: 900;
        font-size: rem-calc(14);
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        color: map-deep-get($colors, "gray", "default");

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
            margin-top: 0;
        }

        #{$elm}__icon--up {
            display: none;
        }

        #{$elm}__icon--down {
            display: inline-block;
        }

        svg {
            fill: map-deep-get($colors, "gray", "default");
            height: 1em;
            width: 1em;
        }

        &--active {
            #{$elm}__icon--up {
                display: inline-block;
            }

            #{$elm}__icon--down {
                display: none;
            }

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &__list {
        display: none;

        @include media-breakpoint-up(md) {
            display: inline-block;
            margin: 20px 0;
        }

        &--active {
            display: block;
        }
    }

    &__reset {
        transition: $transition-default;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }

        &:hover {
            color: map-deep-get($colors, "black", "default");
            background: map-deep-get($colors, "brand", "primary");
            padding-right: 30px;
        }
    }
}