.project {
    &__header {
        margin-bottom: 50px;
    }

    &__title {
        margin-bottom: 0.25em;
    }

    &__category {
        color: map-deep-get($colors, "gray", "default");
        -webkit-font-smoothing: antialiased;
        margin-bottom: 0;
    }

    &__image {
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-top: 55px;
            margin-bottom: 55px;
        }
    }
}