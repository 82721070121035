.footer {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    position: relative;
    z-index: 15;
    margin-top: 50px;

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 900;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.5em;
                height: 0.5em;
                background: map-deep-get($colors, "white", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;
    }

    &__reference {
        font-size: 14px;
        font-weight: 900;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        display: block;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}