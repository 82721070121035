.project-item {
    height: 100%;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    $elm: &;

    &__inner {
        height: 100%;
        display: flex;
        align-content: flex-start;
        flex-flow: column;
        width: 100%;

        &:hover {
            #{$elm}__link {
                color: map-deep-get($colors, "black", "default");
                background: map-deep-get($colors, "brand", "primary");
                padding-right: 30px;
            }
        }
    }

    &__image-wrapper {
        background: map-deep-get($colors, "gray", "default");
        position: relative;
        min-height: 60vw;
        padding-top: 66.5%;
        width: 100%;
        display: block;

        @include media-breakpoint-up(md) {
            min-height: 0;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        color: map-deep-get($colors, "black", "default");
    }

    &__content {
        align-content: space-between;
        background: map-deep-get($colors, "black", "default");
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        padding: 20px 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 20px 0 40px;
        }
    }

    &__content-top,
    &__content-bottom {
        flex: 1 1 100%;
    }

    &__category {
        font-weight: 600;
        line-height: 1.9em;
        font-size: rem-calc(20);
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        opacity: 0.5;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__link {
        transition: $transition-default;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }
    }
}