﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    font-size: rem-calc(40);
    font-weight: 900;
    line-height: 1.18em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(60);
    }
}

h2, .h2 {
    font-size: rem-calc(26);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(40);
    }
}

h3, .h3 {
    font-size: rem-calc(23);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(35);
    }
}

h4, .h4 {
    font-size: rem-calc(16);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(25);
    }
}

h5, .h5 {
    font-size: rem-calc(14);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;
}

h6, .h6 {
    font-size: rem-calc(10);
    font-weight: 900;
    line-height: 1em;
    margin-bottom: 0.5em;
}

.intro,
.lead {
    font-weight: 600;
    line-height: 1.64em;
    font-size: rem-calc(18);
    color: map-deep-get($colors, "gray", "default");
    -webkit-font-smoothing: antialiased;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(25);
    }
}