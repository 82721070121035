.projects {
    &__content {
        margin-top: 55px;
        margin-bottom: 55px;

        @include media-breakpoint-up(md) {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include media-breakpoint-up(md) {
            margin-top: 110px;
            margin-bottom: 110px;
        }
    }
}